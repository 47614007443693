import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/core';

import {
  StateBuilderService,
  WorkflowEntityState,
} from 'src/app/core/state-builder.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { AppService } from 'src/app/core/app.service';

import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

import { BoardService } from 'src/app/boards/services/board.service';
import { BoardCardView } from 'src/app/boards/models/board.interface';

@Component({
  selector: 'tmt-board-mini-card',
  templateUrl: './board-mini-card.component.html',
  styleUrl: './board-mini-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardMiniCardComponent implements OnInit {
  @Input() public card: BoardCardView;
  @Input() public index: number;

  public stateForEntity: WorkflowEntityState;

  constructor(
    public stateBuilderService: StateBuilderService,
    public boardService: BoardService,
    private stateService: StateService,
    private navigationService: NavigationService,
    private appService: AppService,
  ) {}

  public ngOnInit(): void {
    if (this.card.entity?.type) {
      this.stateForEntity = this.stateBuilderService.getStateForWorkflowEntity(
        this.card.entity.id,
        this.card.entity.type,
        this.card.entity.id,
      );
    } else {
      this.stateForEntity = {
        state: this.stateBuilderService.entityTypeToStateName.get(
          this.boardService.config.entityType,
        ),
        params: {
          entityId: this.card.entity.id,
          routeMode: RouteMode.continue,
          navigation: this.navigationService.selectedNavigationItem?.name,
        },
      };
    }
  }

  /**
   * Runs custom function or redirects to another state.
   *
   * @param event MouseEvent.
   */
  public clickHandler(event: MouseEvent): void {
    if ((event.target as HTMLElement).tagName === 'A') {
      return;
    }

    if (this.card.onClick) {
      this.card.onClick();
    }
  }

  /**
   * Guesses type of property.
   *
   * @param property MetaEntityBaseProperty.
   * @returns type for template.
   */
  public guessTemplateType(property: MetaEntityBaseProperty): string {
    const propertyValue = this.card.entity[property.name];

    if (propertyValue === null) {
      return 'empty';
    }

    if (typeof propertyValue === 'string') {
      return 'string';
    }

    if (propertyValue?.id && propertyValue?.name) {
      return 'lookupValue';
    }

    return 'unknown';
  }
}
