import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MenuComponent } from '../shared/components/features/menu/menu.component';
import { InfoPopupService } from '../shared/components/features/info-popup/info-popup.service';
import {
  DefaultPopperModifier,
  InfoPopupEntry,
} from 'src/app/shared/components/features/info-popup/info-popup.interface';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _events: Array<string> = ['click'];
  private menuEl: HTMLDivElement;

  constructor(
    private _ngZone: NgZone,
    private infoPopupService: InfoPopupService,
    @Inject(DOCUMENT) private document,
  ) {
    this._onClickBody = this._onClickBody.bind(this);
  }

  /**
   * Opens menu.
   *
   * @param event MouseEvent
   * @param items Action's buttons of menu
   * @param context Data for callback `allowedFn((context) => boolean)` (`MenuItem` property)
   *
   */
  public open(event: MouseEvent, items: MenuItem[], context?: any): void {
    this.close();

    this.menuEl = this.document.createElement('div');
    this.menuEl.classList.add('position-absolute');
    this.menuEl.classList.add('d-block');
    this.menuEl.classList.add('fixed');
    this.menuEl.style.zIndex = '9999';

    this.menuEl.style.left = `${event.clientX}px`;
    this.menuEl.style.top = `${event.clientY}px`;

    const popperModifiers: DefaultPopperModifier[] = [
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
    ];
    const popupEntry: InfoPopupEntry = {
      target: this.menuEl,
      data: {
        component: MenuComponent,
        componentParams: {
          inputs: {
            menuItems: items,
            context,
          },
        },
      },
      popperModifiers,
      placement: 'bottom-start',
      isHideArrow: true,
      containerStyles: { padding: '8px 0px' },
      mutationObserverElement: 'popup',
    };

    this.document.body.appendChild(this.menuEl);
    this.infoPopupService.open(popupEntry);
    this._initClickOutsideListener();
  }

  public close() {
    if (this.menuEl) {
      this._removeClickOutsideListener();
      this.menuEl.remove();
    }
  }

  private _onClickBody(ev: Event) {
    if (
      this.menuEl &&
      this.document.body.contains(<Node>ev.target) &&
      !this.menuEl.contains(ev.target as Node)
    ) {
      this.close();
    }
  }

  private _initClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach((e) =>
        this.document.body.addEventListener(e, this._onClickBody),
      );
    });
  }

  private _removeClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach((e) =>
        this.document.body.removeEventListener(e, this._onClickBody),
      );
    });
  }
}

export interface MenuItem {
  name: string;
  label: string | ((context?: any) => string);
  handlerFn: (context?: any) => void;
  iconClass?: string;
  allowedFn?: (context?: any) => boolean;
  /** Menu item's sub actions. If 'null', 'undefined' or '[]' menu item won't be shown. */
  subActions?: MenuItem[];
}
