import {
  DestroyRef,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  firstValueFrom,
  of,
  switchMap,
  tap,
} from 'rxjs';
import _ from 'lodash';

import { NotificationService } from 'src/app/core/notification.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';
import { MenuService, MenuItem } from 'src/app/core/menu.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { BlockUIService } from 'src/app/core/block-ui.service';

import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DragAndDropData } from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';
import { DragDropService } from 'src/app/shared/services/drag-drop';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

import { WorkflowTaskCardComponent } from 'src/app/workflow-tasks/card/workflow-task-card.component';
import { BoardCardViewProperties } from 'src/app/settings-app/boards/model/board.model';

import {
  BoardCardView,
  BoardEvent,
  BoardColumnView,
} from 'src/app/boards/models/board.interface';
import {
  BOARD_CONFIG,
  BoardConfig,
} from 'src/app/boards/models/board-config.interface';
import { BoardDataService } from 'src/app/boards/services/board-data.service';
import { BoardColumnHeaderFormComponent } from 'src/app/boards/components/board/column-header-form/board-column-header-form.component';
import { BoardMiniCardBuilderModalComponent } from 'src/app/boards/components/board/mini-card-builder-modal/board-mini-card-builder-modal.component';

@Injectable()
export class BoardService implements OnDestroy {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public event$ = new Subject<BoardEvent>();
  public columnsDependencies: Record<string, string[]> = {};
  public columns: BoardColumnView[] = [];
  public cards: BoardCardView[] = [];
  public cardsByColumns: Record<string, BoardCardView[]>;

  private groupBy = 'columnId';
  private cardMenuChangeStateSubActions: MenuItem[];
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(BOARD_CONFIG) public readonly config: BoardConfig | null,
    private boardDataService: BoardDataService,
    private offCanvasService: OffCanvasService,
    private notificationService: NotificationService,
    private actionPanelService: ActionPanelService,
    private filterService: FilterService,
    private dragDropService: DragDropService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private menuService: MenuService,
    private modal: NgbModal,
    private stateService: StateService,
    private navigationService: NavigationService,
    private blockUI: BlockUIService,
  ) {
    this.loadingSubject.next(true);
    this.loadBoard(this.getFilter()).subscribe(() =>
      this.loadingSubject.next(false),
    );

    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.infoPopupService.close();
  }

  /**
   * Opens task card in `offCanvas` (aside).
   *
   * @param taskId task's id.
   */
  public openTaskCard(taskId: string): void {
    this.offCanvasService.openOffCanvas(taskId, {
      data: {
        component: WorkflowTaskCardComponent,
        componentParams: {
          inputs: {
            entityId: taskId,
          },
        },
      },
    });
  }

  /** Opens card builder modal.  If modal result if successful, reloads board with new card structure.  */
  public openCardBuilder(): void {
    const modalRef = this.modal.open(BoardMiniCardBuilderModalComponent, {
      injector: this.injector,
    });

    modalRef.result.then(
      (result: BoardCardViewProperties[] | undefined) => {
        if (result) {
          this.loadBoard(this.getFilter()).subscribe();
        }
      },
      () => null,
    );
  }

  /** Changes state to board settings. */
  public openSettings(): void {
    this.stateService.go('settings.board', {
      entityId: this.config.id,
      routeMode: RouteMode.continue,
      navigation: this.navigationService.selectedNavigationItem?.name,
    });
  }

  /**
   * Gets list of board card views.
   *
   * @param filter filter for getting needed entries.
   *
   * TODO: not correct
   */
  public getTasks(filter?: any): Observable<BoardCardView[]> {
    return this.boardDataService.getCards(filter).pipe(
      tap((cards) => {
        this.cards = cards;
      }),
      catchError((error) => {
        this.notificationService.error(error.message);
        return of([]);
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /**
   * Loads board's states and tasks.
   *
   * @param filter filter for getting needed tasks.
   */
  public loadBoard(filter?: any): Observable<BoardCardView<any>[]> {
    if (!this.config) {
      return of(null);
    }

    this.blockUI.start();

    return this.boardDataService.getBoardConfig().pipe(
      tap((columns) => {
        this.columns = columns;
        this.initColumnsDependencies();
        this.initColumnsActions();
      }),
      switchMap(() => this.getTasks(filter)),
      tap((cards) => {
        this.cardsByColumns = _.groupBy(
          cards,
          (item) => `${item[this.groupBy]}`,
        );

        // TODO: not correct
        for (const column of this.columns) {
          if (!this.cardsByColumns[column.id]) {
            this.cardsByColumns[column.id] = [];
          }
        }

        this.initCardActions();
        this.event$.next({
          target: 'track',
          id: null,
          action: 'updated',
        });

        this.blockUI.stop();
      }),
      catchError((error) => {
        this.notificationService.error(error.message);
        this.blockUI.stop();
        return of(null);
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /**
   * Updates card after drop.
   *
   * @param cardId Card id.
   * @param newColumnCode New column after drop.
   */
  public async updateCard(
    cardId: string,
    data: DragAndDropData<BoardCardView>,
  ): Promise<void> {
    const newColumn = this.columns.find((c) => c.id === data.toGroupName);
    const card = this.cards.find((c) => c.id === cardId);

    if (data.fromGroupName === data.toGroupName) {
      this.boardDataService
        .updateCard(card.id, {
          columnId: card.columnId,
          index: data.newIndex,
        })
        .subscribe();

      return;
    }

    const stateChangeResult = await this.boardDataService.setState(
      card,
      newColumn.stateId,
    );

    if (stateChangeResult) {
      card.entity.stateId = newColumn.stateId;
      card.entity.state = newColumn.state;
      card.columnId = newColumn.id;
      card.actions.find((action) => action.name === 'changeState').subActions =
        this.cardMenuChangeStateSubActions.filter(
          (subAction) => subAction.name !== newColumn.stateId,
        );

      this.boardDataService
        .updateCard(card.id, {
          columnId: card.columnId,
          index: data.newIndex,
        })
        .subscribe();

      this.event$.next({
        target: 'track',
        id: null,
        action: 'disableDnD',
        data: false,
      });
    } else {
      this.dragDropService.data = data;
      const oldColumn = this.columns.find((c) => c.id === data.fromGroupName);

      this.event$.next({
        target: 'track',
        id: newColumn.id,
        action: 'rollBackFrom',
        data,
      });

      this.event$.next({
        target: 'track',
        id: oldColumn.id,
        action: 'rollBackTo',
        data,
      });
    }
  }

  /**
   * Adds new column.
   *
   * @param data column properties.
   * @returns updated column and cards if save succeeded, otherwise `false`.
   */
  public addColumn(
    data: Partial<BoardColumnView>,
  ): Promise<BoardCardView[] | boolean> {
    const updatedColumns = this.columns.slice(0);
    const state = this.boardDataService.states.find(
      (state) => state.id === data.state.id,
    );

    updatedColumns.push({
      actions: [],
      id: state.id,
      stateId: state.id,
      header: data.header ?? state.name,
      style: state.style,
      index: this.columns.length,
    });

    return firstValueFrom(
      this.boardDataService
        .saveUpdatedColumns(updatedColumns)
        .pipe(switchMap((v) => (v ? this.loadBoard(this.getFilter()) : of(v)))),
    );
  }

  /**
   * Removes column.
   *
   * @param column Board column view.
   */
  public removeColumn(column: BoardColumnView): void {
    this.columns.splice(
      this.columns.findIndex((c) => c.id === column.id),
      1,
    );

    firstValueFrom(this.boardDataService.saveUpdatedColumns(this.columns));
    this.event$.next({
      target: 'column',
      action: 'updated',
      id: column.id,
    });
  }

  /**
   * Updates column.
   *
   * @param id column id.
   * @param data column properties.
   * @returns `true` if save succeeded, otherwise `false`.
   */
  public updateColumn(
    id: string,
    data: Partial<BoardColumnView>,
  ): Promise<boolean> {
    Object.assign(
      this.columns.find((column) => column.id === id),
      data,
    );

    this.event$.next({
      target: 'column',
      action: 'updated',
      id,
    });

    return firstValueFrom(
      this.boardDataService.saveUpdatedColumns(this.columns),
    );
  }

  /* Updates columns after drag and drop. */
  public updateColumnsOrder(): void {
    this.columns.forEach((column, index) => (column.index = index));
    firstValueFrom(this.boardDataService.saveUpdatedColumns(this.columns));
  }

  /**
   * Opens popup with column form.
   *
   * @param target popup target.
   * @param column board column view.
   * @param mode form mode.
   */
  public opensColumnForm(
    target: HTMLElement,
    column: BoardColumnView,
    mode: 'edit' | 'create',
  ): void {
    this.infoPopupService.open<BoardColumnHeaderFormComponent>({
      target,
      data: {
        component: BoardColumnHeaderFormComponent,
        componentParams: {
          inputs: {
            mode,
            column,
          },
          injector: this.injector,
        },
      },
      observeIntersectionDisabled: true,
    });
  }

  /**
   * Opens menu.
   *
   * @param event mouse event.
   * @param actions actions array.
   * @param context menu item context.
   */
  public openMenu(event: MouseEvent, actions: MenuItem[], context: any): void {
    this.infoPopupService.close();
    this.menuService.open(event, actions, context);
    event.preventDefault();
    event.stopPropagation();
  }

  /** Inits card menu actions. */
  private initCardActions(): void {
    this.initCardMenuChangeStateSubActions();

    for (const cardItem of this.cards) {
      cardItem.actions = [
        {
          name: 'changeState',
          label: 'components.boardMiniCardComponent.actions.changeState',
          handlerFn: () => null,
          subActions: this.cardMenuChangeStateSubActions.filter(
            (action) => action.name !== cardItem.entity.stateId,
          ),
        },
        {
          name: 'moveToTop',
          label: 'components.boardMiniCardComponent.actions.moveToTop',
          iconClass: 'bi-arrow-bar-up',
          handlerFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: 0,
                item: card.item,
              },
            }),
          allowedFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.cardsByColumns[card.item.columnId].length > 1 &&
            !!card.oldIndex,
        },
        {
          name: 'moveToBottom',
          label: 'components.boardMiniCardComponent.actions.moveToBottom',
          iconClass: 'bi-arrow-bar-down',
          handlerFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: this.cardsByColumns[card.item.columnId].length - 1,
                item: card.item,
              },
            }),
          allowedFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.cardsByColumns[card.item.columnId].length > 1 &&
            card.oldIndex < this.cardsByColumns[card.item.columnId].length - 1,
        },
      ];
    }
  }

  /** Inits card menu change state sub actions. */
  private initCardMenuChangeStateSubActions(): void {
    this.cardMenuChangeStateSubActions = [];
    for (const state of this.boardDataService.states) {
      this.cardMenuChangeStateSubActions.push({
        name: state.id,
        label: state.name,
        handlerFn: (card: { item: BoardCardView; oldIndex: number }) => {
          const column = this.columns.find(
            (column) => column.stateId === state.id,
          );
          if (!column) {
            this.boardDataService
              .setState(card.item, state.id)
              .then((value) => {
                if (value) {
                  this.cardsByColumns[card.item.columnId].splice(
                    card.oldIndex,
                    1,
                  );
                  this.dragDropService.setOnEnd();
                }
              });
          } else {
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: this.cardsByColumns[column.id].length,
                item: card.item,
                toGroupName: column.id,
              },
            });
          }
        },
      });
    }
  }

  // TODO: not correct, it's like mock data
  private initColumnsDependencies(): void {
    const codes = this.columns.map((column) => column.id);

    codes.forEach((code) => {
      this.columnsDependencies[code] = codes;
    });
  }

  private initColumnsActions(): void {
    this.columns.forEach((column) => {
      column.actions = [
        {
          name: 'edit',
          label: 'shared2.actions.edit',
          iconClass: 'bi bi-pencil',
          handlerFn: (target) => this.opensColumnForm(target, column, 'edit'),
        },
        {
          name: 'remove',
          label: 'shared2.actions.delete',
          iconClass: 'bi bi-trash',
          handlerFn: () => this.removeColumn(column),
        },
      ];
    });
  }

  private initSubscriptions(): void {
    this.filterService?.values$
      .pipe(
        switchMap(() => this.loadBoard(this.getFilter())),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.actionPanelService.reload$
      .pipe(
        switchMap(() => this.loadBoard(this.getFilter())),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private getFilter(): { and: Array<any> } | any {
    const originalFilter = this.filterService?.getODataFilter();

    if (!originalFilter) {
      return null;
    }

    if (!this.config?.filterService || this.config?.isSimpleFilterQuery) {
      return originalFilter;
    }

    const newFilter = {
      and: [],
    };

    for (const value of originalFilter) {
      const item = Object.values(value).pop();

      if (!Array.isArray(item)) {
        newFilter.and.push(item);
      } else if (Array.isArray(item) && item.length) {
        newFilter.and.push({
          or: item.map((i) => Object.values(i).pop()),
        });
      }
    }

    return newFilter;
  }
}
