<div
  class="board__column-header"
  [class.board__column-header--empty]="!boardService.cardsByColumns[column.id]?.length"
>
  <span class="{{ 'text-' + column.style }} trim" [title]="column.header">{{ column.header }}</span>

  @if (column.actions.length && boardService.config.editAllowed) {
    <button
      class="btn btn-sm without-caret ms-auto board__column-header-menu"
      (click)="boardService.openMenu($event, column.actions, el.nativeElement)"
      [ngbTooltip]="'components.boardColumnHeaderComponent.props.columnMenu' | translate"
      placement="top"
      container="body"
    >
      <i class="bi bi-three-dots"></i>
    </button>
  }
</div>
