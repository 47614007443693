import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  OnInit,
  inject,
} from '@angular/core';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { WorkflowIndicatorPopupComponent } from 'src/app/shared/components/features/workflow-indicator/workflow-indicator-popup/workflow-indicator-popup.component';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OffCanvasService } from 'src/app/core/off-canvas.service';

@Component({
  selector: 'tmt-workflow-indicator',
  templateUrl: './workflow-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowIndicatorComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    public lifecycleService: LifecycleService,
    private injector: Injector,
    private popupService: InfoPopupService,
    private el: ElementRef,
    private offCanvasService: OffCanvasService,
    private navigationService: NavigationService,
  ) {}

  /** Open user notification popup. */
  public openPopup(): void {
    this.popupService.open({
      target: this.el.nativeElement,
      data: {
        component: WorkflowIndicatorPopupComponent,
        params: null,
        injector: this.injector,
      },
      containerStyles: { padding: '0' },
    });
  }

  ngOnInit(): void {
    this.offCanvasService.lifecycleInfoUpdated$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => {
        if (value) {
          this.lifecycleService.reloadLifecycle();
          // What is it?!
          //this.navigationService.updateIndicators();
        }
      });
  }
}
