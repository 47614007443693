<div class="menu-wrap">
  @for (item of menuItems; track item.name) {
    @if (item.subActions && item.subActions.length) {
      <button
        type="button"
        class="dropdown-item menu-item d-flex trim"
        title="{{ getLabelString(item.label) | translate }}"
        (click)="$event.stopPropagation(); collapse.toggle()"
      >
        @if (item.iconClass) {
          <span [class]="'icon ' + item.iconClass"></span>
        }
        <span class="trim">{{ getLabelString(item.label) | translate }}</span>
        <i
          class="bi ms-auto"
          [ngClass]="isCollapsed[$index] ? 'bi-chevron-down' : 'bi-chevron-up'"
        ></i>
      </button>
      <div
        [ngbCollapse]="isCollapsed[$index]"
        #collapse="ngbCollapse"
        class="sub-menu"
        (ngbCollapseChange)="onSubActionsCollapseChange($event, $index)"
      >
        @for (subAction of item.subActions; track subAction.name) {
          <ng-container
            [ngTemplateOutlet]="menuItem"
            [ngTemplateOutletContext]="{ item: subAction }"
          />
        }
      </div>
    } @else if (!item.hasOwnProperty('subActions')) {
      <ng-container [ngTemplateOutlet]="menuItem" [ngTemplateOutletContext]="{ item }" />
    }
  }
</div>

<ng-template #menuItem let-item="item">
  <button
    class="dropdown-item menu-item trim"
    (click)="item.handlerFn(context)"
    [disabled]="!canExecute(item)"
    [ngClass]="{ disabled: !canExecute(item) }"
    title="{{ getLabelString(item.label) | translate }}"
    [attr.data-test]="item.name"
  >
    @if (item.iconClass) {
      <span [class]="'icon ' + item.iconClass"></span>
    }
    <span class="trim">{{ getLabelString(item.label) | translate }}</span>
  </button>
</ng-template>
