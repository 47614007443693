import { LocalString } from 'src/app/shared/models/enums/language.enum';

export interface MetaEntity {
  name: string;
  displayNames: LocalString[];
  primitiveProperties: MetaEntityPrimitiveProperty[];
  complexProperties: MetaEntityComplexProperty[];
  navigationProperties: MetaEntityNavigationProperty[];

  //   logAttributes: MetaEntityLogAttribute[];
  usedInLogging: boolean;
  usedInBoards: boolean;
  hasLifecycle: boolean;
  lifecycleKindProperty: string | null;
  lifecycleKindType: string | null;
}

export enum MetaEntityPropertyKind {
  primitive = 'Primitive',
  navigation = 'Navigation',
  collection = 'Collection',
  complex = 'Complex',
}

export interface MetaEntityBaseProperty {
  name: string;
  kind: MetaEntityPropertyKind;
  displayNames: LocalString[];
  type: string;
}

export interface MetaEntityPrimitiveProperty extends MetaEntityBaseProperty {
  usedInHistoryLog: boolean;
  usedInFilters: boolean;
}

export interface MetaEntityComplexProperty extends MetaEntityBaseProperty {
  usedInHistoryLog: boolean;
  properties: string[];
}

export interface MetaEntityNavigationProperty extends MetaEntityBaseProperty {
  usedInHistoryLog: boolean;
  usedInFilters: boolean;
  keyProperty: string;
}
