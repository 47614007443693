import { Navigation } from '../models/navigation/navigation';
import { AppService } from '../../core/app.service';
import { PermissionType } from '../models/inner/permission-type.enum';
import { Feature } from '../models/enums/feature.enum';
import { TIMESHEET_LIST } from '../lists/timesheet.list';
import { PROJECT_LIST } from 'src/app/shared/lists/project.list';
import { CLIENT_LIST } from 'src/app/shared/lists/client.list';
import { PROGRAM_LIST } from 'src/app/shared/lists/program.list';
import { TIME_OFF_LIST } from 'src/app/shared/lists/time-off.list';
import { EXPENSES_LIST } from 'src/app/shared/lists/expenses.list';
import { RESOURCE_REQUEST_LIST } from 'src/app/shared/lists/resource-request.list';
import { RATE_MATRIX_LIST } from 'src/app/settings-app/rate-matrix/rate-matrix.list';
import { INVOICE_LIST } from 'src/app/shared/lists/invoice.list';
import { ActList } from 'src/app/shared/lists/act.list';
import { CERTIFICATES_LIST } from 'src/app/shared/lists/certificates.list';
import { PROJECT_VERSION_LIST } from 'src/app/shared/lists/project-version.list';

export const MY_APP_NAVIGATION: Navigation = {
  name: 'my',

  applicationTitle: 'shared.apps.my',
  groups: [
    {
      name: 'inbox',
      header: 'my.navigation.inbox.header',
      items: [
        {
          name: 'my.inboxTimesheets',
          state: 'timesheets',
          header: 'my.navigation.inbox.timesheets.header',
          hint: 'my.navigation.inbox.timesheets.hint',
          stateParams: {
            view: 'with-my-task',
          },
          indicator: {
            hint: 'my.navigation.inbox.timesheets.indicator',
            list: TIMESHEET_LIST,
            viewName: 'with-my-task',
          },
        },

        {
          name: 'my.inboxTimeOff',
          state: 'timeOffRequests',
          header: 'my.navigation.inbox.timeOff.header',
          hint: 'my.navigation.inbox.timeOff.hint',
          stateParams: {
            view: 'with-my-task',
          },
          indicator: {
            hint: 'my.navigation.inbox.timeOff.indicator',
            list: TIME_OFF_LIST,
            viewName: 'with-my-task',
          },
        },

        {
          name: 'my.inboxExpenseRequests',
          state: 'expensesRequests',
          header: 'my.navigation.inbox.expenseRequests.header',
          hint: 'my.navigation.inbox.expenseRequests.hint',
          stateParams: {
            view: 'with-my-task',
          },
          indicator: {
            hint: 'my.navigation.inbox.expenseRequests.indicator',
            list: EXPENSES_LIST,
            viewName: 'with-my-task',
          },
        },

        {
          name: 'my.inboxProjects',
          state: 'projects',
          header: 'my.navigation.inbox.projects.header',
          hint: 'my.navigation.inbox.projects.hint',
          indicator: {
            hint: 'my.navigation.inbox.projects.indicator',
            list: PROJECT_LIST,
            viewName: 'with-my-task',
          },
          stateParams: {
            view: 'with-my-task',
          },
        },

        {
          name: 'my.inboxProjectVersions',
          state: 'projectsVersions',
          header: 'my.navigation.inbox.projectVersions.header',
          hint: 'my.navigation.inbox.projectVersions.hint',
          indicator: {
            hint: 'my.navigation.inbox.projectVersions.indicator',
            list: PROJECT_VERSION_LIST,
            viewName: 'with-my-task',
          },
          stateParams: {
            view: 'with-my-task',
          },
        },

        {
          name: 'my.inboxResourceRequests',
          state: 'resourceRequests',
          header: 'my.navigation.inbox.resourceRequests.header',
          hint: 'my.navigation.inbox.resourceRequests.hint',
          indicator: {
            hint: 'my.navigation.inbox.resourceRequests.indicator',
            list: RESOURCE_REQUEST_LIST,
            viewName: 'with-my-task',
          },
          stateParams: {
            view: 'with-my-task',
          },
        },

        {
          name: 'my.inboxInvoices',
          state: 'invoices',
          header: 'my.navigation.inbox.invoices.header',
          hint: 'my.navigation.inbox.invoices.hint',
          stateParams: {
            view: 'with-my-task',
          },
          indicator: {
            hint: 'my.navigation.inbox.invoices.indicator',
            list: INVOICE_LIST,
            viewName: 'with-my-task',
          },
        },

        {
          name: 'my.inboxActs',
          state: 'actsOfAcceptance',
          header: 'my.navigation.inbox.acts.header',
          hint: 'my.navigation.inbox.acts.hint',
          stateParams: {
            view: 'with-my-task',
          },
          indicator: {
            hint: 'my.navigation.inbox.acts.indicator',
            list: ActList,
            viewName: 'with-my-task',
          },
        },

        {
          name: 'my.inboxCertificates',
          state: 'certificates',
          header: 'my.navigation.inbox.certificates.header',
          hint: 'my.navigation.inbox.certificates.hint',
          indicator: {
            hint: 'my.navigation.inbox.certificates.indicator',
            list: CERTIFICATES_LIST,
            viewName: 'with-my-task',
          },
          stateParams: {
            view: 'with-my-task',
          },
        },

        {
          name: 'my.inboxRateMatrix',
          state: 'settings.rateMatrices',
          header: 'my.navigation.inbox.rateMatrix.header',
          hint: 'my.navigation.inbox.rateMatrix.hint',
          indicator: {
            hint: 'my.navigation.inbox.rateMatrix.indicator',
            list: RATE_MATRIX_LIST,
            viewName: 'with-my-task',
          },
          stateParams: {
            view: 'with-my-task',
          },
        },

        {
          name: 'my.inboxAll',
          state: 'workflowTasks',
          header: 'my.navigation.inbox.all.header',
          hint: 'my.navigation.inbox.all.hint',
          stateParams: {
            view: 'my-all',
          },
          isVisible: false,
        },
      ],
    },

    {
      name: 'sent',
      header: 'my.navigation.sent.header',
      items: [
        {
          name: 'my.sentTimesheets',
          state: 'timesheets',
          header: 'my.navigation.sent.timesheets.header',
          hint: 'my.navigation.sent.timesheets.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.timesheets.indicator',
            list: TIMESHEET_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentTimeOff',
          state: 'timeOffRequests',
          header: 'my.navigation.sent.timeOff.header',
          hint: 'my.navigation.sent.timeOff.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.timeOff.indicator',
            list: TIME_OFF_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentExpenseRequests',
          state: 'expensesRequests',
          header: 'my.navigation.sent.expenseRequests.header',
          hint: 'my.navigation.sent.expenseRequests.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.expenseRequests.indicator',
            list: EXPENSES_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentProjects',
          state: 'projects',
          header: 'my.navigation.sent.projects.header',
          hint: 'my.navigation.sent.projects.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.projects.indicator',
            list: PROJECT_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentProjectVersions',
          state: 'projectsVersions',
          header: 'my.navigation.sent.projectVersions.header',
          hint: 'my.navigation.sent.projectVersions.hint',
          indicator: {
            hint: 'my.navigation.sent.projectVersions.indicator',
            list: PROJECT_VERSION_LIST,
            viewName: 'with-my-workflow',
          },
          stateParams: {
            view: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentResourceRequests',
          state: 'resourceRequests',
          header: 'my.navigation.sent.resourceRequests.header',
          hint: 'my.navigation.sent.resourceRequests.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.resourceRequests.indicator',
            list: RESOURCE_REQUEST_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentInvoices',
          state: 'invoices',
          header: 'my.navigation.sent.invoices.header',
          hint: 'my.navigation.sent.invoices.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.invoices.indicator',
            list: INVOICE_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentActs',
          state: 'actsOfAcceptance',
          header: 'my.navigation.sent.acts.header',
          hint: 'my.navigation.sent.acts.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.acts.indicator',
            list: ActList,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentCertificates',
          state: 'certificates',
          header: 'my.navigation.sent.certificates.header',
          hint: 'my.navigation.sent.certificates.hint',
          indicator: {
            hint: 'my.navigation.sent.certificates.indicator',
            list: CERTIFICATES_LIST,
            viewName: 'with-my-workflow',
          },
          stateParams: {
            view: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentRateMatrix',
          state: 'settings.rateMatrices',
          header: 'my.navigation.sent.rateMatrix.header',
          hint: 'my.navigation.sent.rateMatrix.hint',
          indicator: {
            hint: 'my.navigation.sent.rateMatrix.indicator',
            list: RATE_MATRIX_LIST,
            viewName: 'with-my-workflow',
          },
          stateParams: {
            view: 'with-my-workflow',
          },
        },

        {
          name: 'my.workflow',
          state: 'workflow-instances',
          header: 'my.navigation.sent.all.header',
          hint: 'my.navigation.sent.all.hint',
          isVisible: false,
          stateParams: {
            view: 'my',
          },
        },
      ],
    },

    // {
    //   name: 'workflow',
    //   header: 'my.navigation.workflow.header',
    //   items: [
    //     {
    //       name: 'my.workflowTasksActive',
    //       state: 'workflowTasks',
    //       header: 'my.navigation.workflow.activeAssignments.header',
    //       hint: 'my.navigation.workflow.activeAssignments.hint',
    //       stateParams: {
    //         view: 'my-active',
    //       },
    //       indicator: {
    //         hint: 'my.navigation.workflow.tasksActive.indicator',
    //         list: WORKFLOW_TASK_LIST,
    //         viewName: 'my-active',
    //       },
    //     },
    //     {
    //       name: 'my.workflowTasksAll',
    //       state: 'workflowTasks',
    //       header: 'my.navigation.workflow.assignments.header',
    //       hint: 'my.navigation.workflow.assignments.hint',
    //       stateParams: {
    //         view: 'my-all',
    //       },
    //       isVisible: false,
    //     },
    //     {
    //       name: 'my.activeWorkflow',
    //       state: 'workflow-instances',
    //       header: 'my.navigation.workflow.activeWorkflows.header',
    //       hint: 'my.navigation.workflow.activeWorkflows.hint',
    //       stateParams: {
    //         view: 'my-active',
    //       },
    //       indicator: {
    //         hint: 'my.navigation.workflow.activeWorkflows.indicator',
    //         list: WORKFLOW_INSTANCE_LIST,
    //         viewName: 'my-active',
    //       },
    //     },

    //     {
    //       name: 'my.workflow',
    //       state: 'workflow-instances',
    //       header: 'my.navigation.workflow.workflows.header',
    //       hint: 'my.navigation.workflow.workflows.hint',
    //       stateParams: {
    //         view: 'my',
    //       },
    //     },
    //   ],
    // },
    // {
    //   name: 'issues',
    //   header: 'shared2.groups.my.navigation.issues.header',
    //   items: [
    //     {
    //       name: 'my.issues',
    //       state: 'issues',
    //       header: 'shared2.groups.my.navigation.issues.all.header',
    //       hint: 'shared2.groups.my.navigation.issues.all.hint',
    //       stateParams: {
    //         view: 'all',
    //       },
    //     },
    //   ],
    // },
    {
      name: 'tracking',
      header: 'my.navigation.tracking.header',
      items: [
        {
          name: 'my.currentTimesheet',
          state: 'currentTimesheet',
          header: 'my.navigation.tracking.currentTimesheet.header',
          hint: 'my.navigation.tracking.currentTimesheet.hint',
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('TimeSheet', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.timesheets),
        },
        {
          name: 'my.timesheetsExpired',
          state: 'timesheets',
          header: 'my.navigation.tracking.timesheetsExpired.header',
          hint: 'my.navigation.tracking.timesheetsExpired.hint',
          stateParams: {
            view: 'my-expired',
          },
          indicator: {
            hint: 'my.navigation.tracking.timesheetsExpired.indicator',
            list: TIMESHEET_LIST,
            viewName: 'my-expired',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('TimeSheet', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.timesheets),
        },

        {
          name: 'my.timesheetsAll',
          state: 'timesheets',
          header: 'my.navigation.tracking.allTimesheets.header',
          hint: 'my.navigation.tracking.allTimesheets.hint',
          stateParams: {
            view: 'my-all',
          },
          isVisible: true,
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('TimeSheet', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.timesheets),
        },
        {
          name: 'my.timeOffRequestsAll',
          state: 'timeOffRequests',
          header: 'my.navigation.tracking.timeOff.header',
          hint: 'my.navigation.tracking.timeOff.hint',
          stateParams: {
            view: 'my-all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('TimeOffRequest', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.timeOff),
        },
        {
          name: 'my.expensesRequestsAll',
          state: 'expensesRequests',
          header: 'my.navigation.tracking.expenses.header',
          hint: 'my.navigation.tracking.expenses.hint',
          stateParams: {
            view: 'my-all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('ExpenseRequest', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.expenses),
        },
      ],
    },

    {
      header: 'my.navigation.projects.header',
      name: 'projects',
      items: [
        {
          name: 'my.projects',
          state: 'projects',
          isVisible: false,
          header: 'my.navigation.projects.myProjects.header',
          hint: 'my.navigation.projects.myProjects.hint',
          stateParams: {
            view: 'my',
          },

          indicator: {
            hint: 'my.navigation.projects.myProjects.header',
            viewName: 'my',
            list: PROJECT_LIST,
          },
        },
        {
          name: 'my.clients',
          state: 'clients',
          isVisible: false,
          header: 'my.navigation.projects.myClients.header',
          hint: 'my.navigation.projects.myClients.hint',
          stateParams: {
            view: 'my',
          },

          indicator: {
            hint: 'my.navigation.projects.myClients.header',
            viewName: 'my',
            list: CLIENT_LIST,
          },
        },
        {
          name: 'my.programs',
          state: 'programs',
          isVisible: false,
          header: 'my.navigation.projects.myPrograms.header',
          hint: 'my.navigation.projects.myPrograms.hint',
          stateParams: {
            view: 'my',
          },

          indicator: {
            hint: 'my.navigation.projects.myPrograms.header',
            viewName: 'my',
            list: PROGRAM_LIST,
          },
        },
      ],
    },

    {
      name: 'work',
      header: 'my.navigation.work.header',
      items: [
        {
          name: 'my.profile',
          state: 'employee',
          stateParams: {
            entityId: 'my',
          },
          header: 'my.navigation.work.profile.header',
          hint: 'my.navigation.work.profile.hint',
        },
        {
          name: 'my.certificates',
          state: 'certificates',
          stateParams: {
            view: 'my',
          },

          header: 'my.navigation.work.certificates.header',
          hint: 'my.navigation.work.certificates.hint',
        },
      ],
    },
  ],
};
