import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChromeService } from 'src/app/core/chrome.service';
import { InfoPopupService } from '../info-popup/info-popup.service';
import { MenuItem, MenuService } from 'src/app/core/menu.service';

@Component({
  selector: 'tmt-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() menuItems: MenuItem[];
  @Input() context: any | null = null;
  /** Shows menu items sub actions collapse. */
  public isCollapsed: Record<string, boolean> = {};

  private destroyed$ = new Subject<void>();

  constructor(
    private menuService: MenuService,
    private chrome: ChromeService,
    private popupService: InfoPopupService,
  ) {}

  ngOnInit(): void {
    this.menuItems.forEach((item, index) => {
      if (item.subActions && item.subActions.length) {
        this.isCollapsed[index] = true;
      }
    });

    // SetTimeout needs for align popper in small right gap
    setTimeout(() => {
      this.popupService.update();
    });

    this.initSubscribers();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Returns label string.
   *
   * @param label label element.
   * @returns label computed to string.
   */
  public getLabelString(label: string | ((context?: any) => string)): string {
    if (!label) {
      return;
    }
    if (typeof label === 'string') {
      return label;
    }
    return label(this.context);
  }

  public canExecute(item: MenuItem): boolean {
    return item.allowedFn ? item.allowedFn(this.context) : true;
  }

  /** Check have at least one icon. */
  public checkHasIcon(): boolean {
    return !!this.menuItems.find((item) => item.iconClass);
  }

  /**
   * Actions after sub menu item collapse changes.
   *
   * @param collapsed boolean shows is item collapsed.
   * @param index item's with sub actions index.
   */
  public onSubActionsCollapseChange(collapsed: boolean, index: number): void {
    for (const index of Object.keys(this.isCollapsed)) {
      this.isCollapsed[index] = true;
    }
    this.isCollapsed[index] = collapsed;
  }

  private initSubscribers(): void {
    merge(this.chrome.mainAreaSize$, this.chrome.scroll$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.menuService.close());
  }
}
