<div class="toolbar-container">
  <wp-rbc-view-switcher></wp-rbc-view-switcher>
  <div class="group">
    <button
      *ngIf="!(service.readonly$ | async)"
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.create' | translate }}"
      (click)="addEntry()"
    >
      <span class="bi bi-plus-lg bi-15" aria-hidden="true"></span>
      {{ 'shared.actions.create' | translate }}
    </button>

    <button
      type="button"
      [disabled]="!service.canBeExecuted('edit', service.selectedRow)"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      (click)="service.execute('edit', service.selectedGroup)"
    >
      <span class="bi bi-pencil" aria-hidden="true"></span>
    </button>
    <button
      *ngIf="!(service.readonly$ | async)"
      [disabled]="!service.canBeExecuted('delete', service.selectedRow)"
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.delete' | translate }}"
      (click)="service.execute('delete', service.selectedRow)"
    >
      <span class="bi bi-trash3" aria-hidden="true"></span>
    </button>
  </div>

  <div class="group">
    <button
      *ngIf="projectBillingService.isWorkProjectVersion"
      type="button"
      [disabled]="!service.canBeExecuted('createInvoice', service.selectedRow)"
      class="btn btn-default"
      title="{{ 'projects.projects.card.billing.actions.createInvoice' | translate }}"
      (click)="service.execute('createInvoice', service.selectedGroup)"
    >
      {{ 'projects.projects.card.billing.actions.createInvoice' | translate }}
    </button>

    <button
      *ngIf="!service.readonly"
      type="button"
      [disabled]="service.loading$ | async"
      title="{{ 'shared.actions.clear' | translate }}"
      class="btn btn-default"
      (click)="service.execute('clear')"
    >
      {{ 'shared.actions.clear' | translate }}
    </button>
  </div>

  <div class="group ms-auto">
    <div ngbDropdown class="d-inline-block" placement="bottom-right">
      <button
        type="button"
        class="btn btn-default"
        ngbDropdownToggle
        title="{{ 'projects.projects.card.finance.grouping.hint' | translate }}"
      >
        {{ projectBillingService.getCurrentGroupingLabel() }}
      </button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem (click)="projectBillingService.setGrouping('none')">
          {{ 'projects.projects.card.finance.grouping.none' | translate }}
        </button>
        <button ngbDropdownItem (click)="projectBillingService.setGrouping('byTasks')">
          {{ 'projects.projects.card.finance.grouping.byTasks' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
