import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { WorkflowTask } from 'src/app/shared/models/entities/workflow-tasks/workflow-task.model';
import {
  MetaEntityBaseProperty,
  MetaEntityPropertyKind,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { Language } from 'src/app/shared/models/enums/language.enum';

import { ProjectFilterService } from 'src/app/projects/list/project-filter/project-filter.service';
import { WorkflowTaskFilterService } from 'src/app/workflow-tasks/list/filter/workflow-task-filter.service';

import { BoardConfig } from './board-config.interface';
import { RequestFilterService } from 'src/app/resource-requests/list/request-filter/request-filter.service';

export const REQUIRED_PROPERTIES: ReadonlyArray<MetaEntityBaseProperty> = [
  {
    name: 'Name',
    type: 'String',
    kind: MetaEntityPropertyKind.primitive,
    displayNames: [
      {
        language: Language.ru,
        value: 'Наименование',
      },
      {
        language: Language.en,
        value: 'Name',
      },
    ],
  },
];

export const BOARD_WORKFLOW_TASK: BoardConfig<WorkflowTask> = {
  entityType: 'Task',
  collection: 'Tasks',
  filterService: WorkflowTaskFilterService,
  cardStructure: [],
};

export const BOARD_PROJECT: BoardConfig = {
  entityType: 'Project',
  collection: 'Projects',
  cardCollection: 'ProjectBoardCards',
  customFieldEntityType: CustomFieldEntityType.Project,
  filterService: ProjectFilterService,
  cardStructure: [],
};

export const BOARD_RESOURCE_REQUEST: BoardConfig = {
  entityType: 'ResourceRequest',
  collection: 'ResourceRequests',
  cardCollection: 'ResourceRequestBoardCards',
  filterService: RequestFilterService,
  isSimpleFilterQuery: true,
  cardStructure: [],
};

export const CONFIG_MAP: ReadonlyMap<string, BoardConfig> = new Map([
  ['Project', BOARD_PROJECT],
  ['ResourceRequest', BOARD_RESOURCE_REQUEST],
]);
