import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  VIEW_NAME,
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
} from 'src/app/shared/tokens';
import { RATE_MATRIX_LIST } from 'src/app/settings-app/rate-matrix/rate-matrix.list';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { RateMatrixListComponent } from 'src/app/settings-app/rate-matrix/list/rate-matrix-list.component';
import { RateMatrixCreationModalComponent } from 'src/app/settings-app/rate-matrix/creation-modal/rate-matrix-creation-modal.component';
import { RateMatrixCardComponent } from 'src/app/settings-app/rate-matrix/card/rate-matrix-card.component';
import { MainRateMatrixComponent } from 'src/app/settings-app/rate-matrix/card/main-matrix/main-matrix.component';
import { MatrixRateComponent } from 'src/app/settings-app/rate-matrix/card/matrix-rate/matrix-rate.component';
import { Grid2Module } from '../../shared-features/grid2/grid2.module';
import { MatrixRateToolbarComponent } from 'src/app/settings-app/rate-matrix/card/matrix-rate/matrix-rate-toolbar/matrix-rate-toolbar.component';
import { CopyMatrixModalComponent } from 'src/app/settings-app/rate-matrix/copy-matrix-modal/copy-matrix-modal.component';
import { MatrixRateLinesComponent } from 'src/app/settings-app/rate-matrix/card/matrix-rate/matrix-rate-lines/matrix-rate-lines.component';
import { RateMatrixStructureChangeModalComponent } from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure-change-modal.component';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';

@NgModule({
  declarations: [
    RateMatrixListComponent,
    RateMatrixCreationModalComponent,
    RateMatrixStructureChangeModalComponent,
    RateMatrixCardComponent,
    MainRateMatrixComponent,
    MatrixRateLinesComponent,
    MatrixRateComponent,
    MatrixRateToolbarComponent,
    CopyMatrixModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    Grid2Module,
    BaseSharedModule,
    CommentsModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.rateMatrices',
          url: '/rate-matrices/{view:viewName}?{navigation}&{route}',
          component: RateMatrixListComponent,
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'settings.rateMatrices',
            routeMode: null,
            view: 'default',
          },
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: RATE_MATRIX_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'settings.rateMatrix',
                queryData: {
                  select: ['id', 'stateId', 'name'],
                  expand: { state: { select: 'isEntityProtected' } },
                },
              } as MassOperationParameters,
            },
          ],
        },
        {
          name: 'settings.rateMatrix',
          params: {
            navigation: 'settings.rateMatrices',
          },
          url: '/rate-matrices/{entityId:guid}',
          redirectTo: 'settings.rateMatrix.main',
          component: RateMatrixCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
        {
          name: 'settings.rateMatrix.main',
          url: '/main',
          component: MainRateMatrixComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.rateMatrix.matrixRate',
          url: '/lines',
          component: MatrixRateComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class RateMatrixModule {}
