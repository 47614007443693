import {
  AfterViewInit,
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Renderer2,
  RendererStyleFlags2,
  inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import _ from 'lodash';
import {
  Observable,
  Subject,
  concatMap,
  debounceTime,
  delay,
  filter,
  first,
  forkJoin,
  fromEvent,
  merge,
  of,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

import { ChromeService } from 'src/app/core/chrome.service';

import { Constants } from 'src/app/shared/globals/constants';
import { Position } from 'src/app/shared/models/inner/position.model';
import {
  DragDropService,
  DragEvent as DragDropEvent,
  DragContext,
} from 'src/app/shared/services/drag-drop';
import {
  DetectedItem,
  DirectionX,
  DirectionY,
  DragAndDropData,
  DragAndDropOptions,
} from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';

@Directive({
  selector: '[tmtDragAndDrop]',
  standalone: true,
})
export class DragAndDropDirective implements AfterViewInit, OnDestroy {
  /* List for drag and drop. */
  @Input('tmtDragAndDrop') items: any[] = [];
  /* Options with d&d areas info. */
  @Input('tmtDragAndDropOptions') options: DragAndDropOptions;
  /* Indicates whether to disable DnD */
  @Input('dndDisabled') dndDisabled: boolean;

  private isCloneOver = false;
  private animating: boolean;
  private readonly animating$ = new Subject<void>();
  private readonly animateTime = 0.3;
  private readonly placeholderClass = 'dnd-placeholder';
  private readonly cloneClass = 'dnd-clone';
  private readonly escapeObservable$ = fromEvent(this.document, 'keydown').pipe(
    filter((event: KeyboardEvent) => event.code === 'Escape'),
    tap(() => {
      if (this.dragData) {
        this.dragData.toGroupName = null;
      }
    }),
  );
  private readonly destroyRef = inject(DestroyRef);

  private get draggableElements(): HTMLElement[] {
    return Array.from(
      this.elementRef.nativeElement.querySelectorAll<HTMLElement>(
        `.${this.options.draggableClass}`,
      ),
    );
  }

  private get dragData(): DragAndDropData | null {
    return this.dragDropService.data;
  }
  private set dragData(data: DragAndDropData | null) {
    this.dragDropService.data = data;
  }

  constructor(
    private dragDropService: DragDropService,
    private chromeService: ChromeService,
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public ngAfterViewInit(): void {
    this.options.sortable = this.options.sortable ?? true;

    this.initStyles();
    this.initSubscribers();

    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'data-group-name',
      this.options.group.name,
    );
  }

  public ngOnDestroy(): void {
    if (
      this.dragData?.clone.element &&
      this.dragData?.fromGroupName === this.options.group.name
    ) {
      this.renderer.removeChild(this.document, this.dragData.clone.element);
    }
  }

  /**
   * Makes dragging element clone element and init it.
   *
   * @param node element for making clone.
   */
  public makeClone(
    node: any,
    className: string = this.cloneClass,
  ): HTMLElement {
    const elRect = (node as HTMLElement).getBoundingClientRect();
    const clone = node.cloneNode(true);

    this.renderer.setStyle(clone, 'position', 'fixed');
    this.renderer.setStyle(clone, 'left', `${elRect.left}px`);
    this.renderer.setStyle(clone, 'top', `${elRect.top}px`);
    this.renderer.setStyle(clone, 'z-index', '9999');
    this.renderer.setStyle(clone, 'width', `${node.offsetWidth}px`);
    this.renderer.setStyle(clone, 'height', `${node.offsetHeight}px`);
    this.renderer.setStyle(clone, 'margin', '0');
    this.renderer.setStyle(clone, 'padding', '0');
    this.renderer.setStyle(clone, 'opacity', 1);
    this.renderer.setStyle(clone, 'transition', 'none');
    this.renderer.setStyle(clone, 'pointer-events', 'none');

    if (className) {
      this.renderer.addClass(clone, className);
    }

    this.renderer.appendChild(this.document.body, clone);

    return clone;
  }

  /**
   * Removes element from DOM and array. Also runs list transform.
   *
   * @param element HTMLElement.
   * @param index index in array.
   * @returns Transform animation observable.
   */
  public destroyElement(
    element: HTMLElement,
    index: number,
  ): Observable<unknown> {
    this.renderer.removeChild(this.elementRef.nativeElement, element);
    this.cdr.detectChanges();
    this.items.splice(index, 1);

    return this.runTransformAnimation(index, {
      translate: this.options.listDirection === 'vertical' ? 'y' : 'x',
      withoutTransition: true,
    }).pipe(takeUntilDestroyed(this.destroyRef));
  }

  /** Creates placeholder. This is needed to change height of column. */
  public makePlaceholder(): void {
    const placeholder = this.makeClone(
      this.dragData.clone.element,
      'dnd-placeholder',
    );
    this.renderer.removeClass(placeholder, this.options.draggableClass);
    this.renderer.removeStyle(placeholder, 'position');
    this.renderer.setStyle(placeholder, 'opacity', 0);
    this.renderer.appendChild(this.elementRef.nativeElement, placeholder);
  }

  /* Makes operations when dragging starts. Sets service initial data. */
  private onDragStart(element: HTMLElement, event: PointerEvent): void {
    let index = this.draggableElements.findIndex(
      (el) => el === element.closest(`.${this.options.draggableClass}`),
    );
    index = index === -1 ? 0 : index;

    const containerRect = this.elementRef.nativeElement.getBoundingClientRect();
    const rect = element.getBoundingClientRect();
    const dragContext: DragContext = {
      leftOffset: event.clientX - rect.left,
      topOffset: event.clientY - rect.top,
    };

    this.dragData = {
      item: this.items[index],
      oldIndex: index,
      fromGroupName: this.options.group.name,
      fromGroupKey: this.options.group.key,
      toGroupName: this.options.group.name,
      clone: {
        element: this.makeClone(element),
        lastDragX: 0,
        lastDragY: 0,
        offsetX: dragContext.leftOffset,
        offsetY: dragContext.topOffset,
        oldPosition: {
          x: rect.x - containerRect.left,
          y: rect.y - containerRect.top,
        },
      },
    };

    this.destroyElement(element, index).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.renderer.setStyle(
      this.document.body,
      'cursor',
      'grabbing',
      RendererStyleFlags2.Important,
    );
    this.renderer.setStyle(
      this.document.body,
      'user-select',
      'none',
      RendererStyleFlags2.Important,
    );

    this.clearSelection();

    this.dragDropService.setOnStart();
    this.dragDropService
      .drag(event, dragContext, {
        container: this.document.querySelector<HTMLElement>('#main-area'),
        target: this.dragData.clone.element,
        takeUntil: takeUntil(this.escapeObservable$),
      })
      .pipe(
        startWith({
          x: event.x - dragContext.leftOffset,
          y: event.y - dragContext.topOffset,
          diffY: 0,
          diffX: 0,
        }),
        filter(() => !!this.dragData),
      )
      .pipe(
        takeUntil(this.escapeObservable$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        this.moveClone(event);
        const toGroupName = this.getDestinationGroup();

        if (toGroupName !== this.dragData.toGroupName) {
          this.dragData.newIndex = undefined;
        }

        this.dragData.toGroupName = toGroupName;
        this.dragDropService.setOnDragOver(toGroupName);
      });

    merge(this.dragDropService.dragEnd$, this.escapeObservable$)
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.renderer.removeStyle(this.document.body, 'cursor');
        this.renderer.removeStyle(this.document.body, 'user-select');

        if (this.dragData?.toGroupName) {
          this.dragDropService.setOnDrop();
        } else {
          this.dragDropService.setOnEnd();
        }
      });

    this.chromeService.scroll$
      .pipe(
        takeUntil(
          merge(this.dragDropService.dragEnd$, this.dragDropService.onEnd$),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        const toGroupName = this.getDestinationGroup();

        if (toGroupName !== this.dragData.toGroupName) {
          this.dragData.newIndex = undefined;
        }

        this.dragData.toGroupName = toGroupName;
        this.dragDropService.setOnDragOver(toGroupName);
      });
  }

  /* Makes operations when dragging element is over droppable area. */
  private onDragOver(): void {
    const { crossed, closest } = this.getClosestElement(
      this.dragData?.clone.element,
      this.draggableElements,
      this.options.listDirection === 'vertical' ? 'y' : 'x',
    );

    if (
      !this.elementRef.nativeElement.querySelector(`.${this.placeholderClass}`)
    ) {
      this.makePlaceholder();
    }

    if (!crossed.element) {
      if (!this.items.length) {
        this.dragData.newIndex = 0;
        return;
      }

      if (
        this.items.length - 1 === closest.index &&
        !this.draggableElements[this.items.length - 1].style.transform
      ) {
        this.dragData.newIndex = closest.index + 1;
        return;
      }

      return;
    }

    const directionValidations: Record<string, DirectionY | DirectionX> = {
      before:
        this.options.listDirection === 'vertical' ? 'to bottom' : 'to right',
      after: this.options.listDirection === 'vertical' ? 'to top' : 'to left',
    };

    // TODO: not correct
    if (this.animating && !this.isCloneOver) {
      return;
    }

    if (
      crossed.breakpoint === 'before' &&
      (this.dragData.clone.movementDirection.includes(
        directionValidations['before'],
      ) ||
        !this.isCloneOver)
    ) {
      const index = crossed.index + (this.isCloneOver ? 1 : 0);
      this.dragData.newIndex = index;

      forkJoin([
        this.restoreTransform(0, index, true),
        this.runTransformAnimation(index, {
          translate: this.options.listDirection === 'vertical' ? 'y' : 'x',
          keepTransform: true,
        }),
      ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.isCloneOver = true;
        });
    }

    if (
      crossed.breakpoint === 'after' &&
      (this.dragData.clone.movementDirection.includes(
        directionValidations['after'],
      ) ||
        !this.isCloneOver)
    ) {
      this.dragData.newIndex = crossed.index;

      this.runTransformAnimation(crossed.index, {
        translate: this.options.listDirection === 'vertical' ? 'y' : 'x',
        keepTransform: true,
      })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.isCloneOver = true;
        });
    }
  }

  /* Makes operations after element dropped. Not calling when event happens out of droppable area. */
  private onDrop(): void {
    if (
      this.dragData?.newIndex === undefined ||
      !this.options.group.put.includes(this.dragData?.fromGroupName)
    ) {
      this.dragDropService.setOnEnd();
      return;
    }

    forkJoin([
      this.runTransformAnimation(
        this.dragData.newIndex ?? this.dragData.oldIndex,
        {
          translate: this.options.listDirection === 'vertical' ? 'y' : 'x',
          keepTransform: true,
        },
      ),
      this.runMagnetAnimation('onDrop'),
    ])
      .pipe(
        tap(() => {
          this.removePlaceholders();
          this.updateItems();
          this.dragDropService.setOnItemUpdate();
          this.dragData = null;
          this.removeClone();
        }),
        switchMap(() => this.restoreTransform(null, null, false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.initStyles();
      });
  }

  /* Uses for returning element into beginning position after element dropped into not droppable area. */
  private onDragEnd(): void {
    forkJoin([
      this.runTransformAnimation(this.dragData.oldIndex, {
        translate: this.options.listDirection === 'vertical' ? 'y' : 'x',
        keepTransform: true,
      }),
      this.runMagnetAnimation('onEnd'),
    ])
      .pipe(
        tap(() => {
          this.items.splice(this.dragData.oldIndex, 0, this.dragData.item);
          this.cdr.detectChanges();
          this.dragData = null;
          this.removeClone();
        }),
        switchMap(() => this.restoreTransform(null, null, false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.initStyles();
      });
  }

  /* Moves cloned item on screen. */
  private moveClone(event: DragDropEvent): void {
    let useX = event.x;
    let useY = event.y;

    this.dragData.clone.movementDirection = [
      event.y - this.dragData.clone.lastDragY < 0 ? 'to top' : 'to bottom',
      event.x - this.dragData.clone.lastDragX < 0 ? 'to left' : 'to right',
    ];

    if (useX === 0 && useY === 0) {
      useX = this.dragData.clone.lastDragX;
      useY = this.dragData.clone.lastDragY;
    }

    if (
      useX === this.dragData.clone.lastDragX &&
      useY === this.dragData.clone.lastDragY
    ) {
      return;
    }

    this.renderer.setStyle(this.dragData.clone.element, 'left', `${useX}px`);
    this.renderer.setStyle(this.dragData.clone.element, 'top', `${useY}px`);

    this.dragData.clone.lastDragX = useX;
    this.dragData.clone.lastDragY = useY;
  }

  private getDestinationGroup(): string | null {
    const groupElements = Array.from(
      this.document.querySelectorAll<HTMLElement>(`[data-group-name]`),
    );

    if (!groupElements.length) {
      return null;
    }

    const closestGroup = this.getClosestElement(
      this.dragData.clone.element,
      groupElements,
      'all',
    ).crossed;

    return !closestGroup.element
      ? null
      : closestGroup.element.dataset['groupName'];
  }

  /**
   * Gets the closest and crossed element from the list.
   *
   * @param target element over list.
   * @param elements list elements.
   * @param byWhat axis along which the coordinates are compared. If option is set to `all`, only the crossed element is returned!
   * @returns items with element, its index and breakpoint.
   */
  private getClosestElement(
    target: HTMLElement,
    elements: HTMLElement[],
    byWhat: 'x' | 'y' | 'all',
  ): {
    crossed: DetectedItem;
    closest: DetectedItem;
  } | null {
    const targetRect = target.getBoundingClientRect();
    const targetCenter: Position = {
      x: targetRect.left + targetRect.width / 2,
      y: targetRect.top + targetRect.height / 2,
    };
    let crossDistance: Position = {
      x: Infinity,
      y: Infinity,
    };
    let crossElement: HTMLElement | null = null;
    let crossIndex: number | null = null;
    let closestDistance: Position = {
      x: Infinity,
      y: Infinity,
    };
    let closestElement: HTMLElement | null = null;
    let closestIndex: number | null = null;
    let breakpoint: 'before' | 'after' | null = null;

    elements.forEach((el, index) => {
      const elRect = el.getBoundingClientRect();
      const elCenter: Position = {
        x: elRect.left + elRect.width / 2,
        y: elRect.top + elRect.height / 2,
      };
      const positionDiff: Position = {
        x: Math.abs(elCenter.x - targetCenter.x),
        y: Math.abs(elCenter.y - targetCenter.y),
      };

      switch (byWhat) {
        case 'x':
          if (positionDiff.x < closestDistance.x) {
            closestDistance = positionDiff;
            closestElement = el;
            closestIndex = index;
          }

          if (
            positionDiff.x < targetRect.width / 2 &&
            positionDiff.x < crossDistance.x
          ) {
            crossDistance = positionDiff;
            crossElement = el;
            crossIndex = index;
            breakpoint = elCenter.x - targetCenter.x > 0 ? 'before' : 'after';
          }

          break;
        case 'y':
          if (positionDiff.y < closestDistance.y) {
            closestDistance = positionDiff;
            closestElement = el;
            closestIndex = index;
          }

          if (
            positionDiff.y < targetRect.height / 2 &&
            positionDiff.y < crossDistance.y
          ) {
            crossDistance = positionDiff;
            crossElement = el;
            crossIndex = index;
            breakpoint = elCenter.y - targetCenter.y > 0 ? 'before' : 'after';
          }

          break;
        case 'all':
          if (
            positionDiff.x < elRect.width / 2 &&
            positionDiff.x <= crossDistance.x &&
            positionDiff.y < elRect.height / 2 &&
            positionDiff.y <= crossDistance.y
          ) {
            crossDistance = positionDiff;
            crossElement = el;
            crossIndex = index;
          }

          break;
      }
    });

    return {
      crossed: {
        index: crossIndex,
        element: crossElement,
        breakpoint,
      },
      closest: {
        index: closestIndex,
        element: closestElement,
        breakpoint,
      },
    };
  }

  /** Updates items after drop. */
  private updateItems(): void {
    if (!this.items) {
      return;
    }

    this.items.splice(
      this.dragData.newIndex ?? this.dragData.oldIndex,
      0,
      this.dragData.item,
    );

    this.cdr.detectChanges();
  }

  private getDraggable(target: HTMLElement): HTMLElement | null {
    if (target.classList.contains(this.options.draggableClass)) {
      return target;
    }

    if (target.parentElement) {
      return this.getDraggable(target.parentElement);
    }

    return null;
  }

  /**
   * Runs transform animation in the list.
   *
   * @param index Start position in array.
   * @param options Animation options.
   *
   * `translate` - `x` is for translateX property, `y` is for translateY property.
   *
   * `withoutTransition` - instant transform.
   *
   * `keepTransform - indicates to save transform after animation complete.
   *
   * @returns Observable on animation complete.
   */
  private runTransformAnimation(
    index: number,
    options: {
      translate: 'x' | 'y';
      withoutTransition?: boolean;
      keepTransform?: boolean;
    },
  ): Observable<unknown> {
    const elements = this.draggableElements
      .slice(index)
      .filter((el) => !el.style.transform);
    const { height, width } =
      this.dragData.clone.element.getBoundingClientRect();

    if (!elements.length) {
      return of(true);
    }

    elements.forEach((el) => {
      if (options.withoutTransition) {
        this.renderer.removeStyle(el, 'transition');
      }

      this.renderer.setStyle(
        el,
        'transform',
        options.translate === 'x'
          ? `translateX(calc(${width}px + ${this.options.listGap}px))`
          : `translateY(calc(${height}px +  ${this.options.listGap}px))`,
      );
    });

    if (options.withoutTransition) {
      return of(true).pipe(
        delay(0),
        tap(() => {
          this.initStyles();
        }),
      );
    }

    this.animating$.next();

    return merge(
      fromEvent(elements[0], 'transitionend'),
      timer(this.animateTime * 1000),
    ).pipe(
      first(),
      tap(() => {
        elements.forEach((el) => {
          if (!options.keepTransform) {
            this.renderer.removeStyle(el, 'transform');
          }
        });
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /**
   * Removes transform property from items of list.
   *
   * @param from Array start index.
   * @param to Array last index.
   * @param animated Indicates whether to save animation.
   * @returns Observable on animation complete.
   */
  public restoreTransform(
    from?: number | null,
    to?: number | null,
    animated?: boolean,
  ): Observable<Event | boolean> {
    const elements = this.draggableElements
      .slice(from ?? undefined, to ?? undefined)
      .filter((el) => el.style.transform);

    if (!elements.length) {
      return of(true);
    }

    elements.forEach((el) => {
      if (!animated) {
        this.renderer.removeStyle(el, 'transition');
      }

      this.renderer.removeStyle(el, 'transform');
    });

    if (animated) {
      this.animating$.next();

      return fromEvent(elements[0], 'transitionend').pipe(
        first(),
        takeUntilDestroyed(this.destroyRef),
      );
    } else {
      return of(true).pipe(
        delay(0),
        tap(() => this.initStyles()),
      );
    }
  }

  /** Runs the return animation for the clone element. */
  private runMagnetAnimation(type: 'onEnd' | 'onDrop'): Observable<any> {
    const position: Position = {
      x: 0,
      y: 0,
    };
    const elements = this.draggableElements;
    const index = this.dragData.newIndex ?? this.dragData.oldIndex;
    const containerRect = this.elementRef.nativeElement.getBoundingClientRect();
    const targetEl = elements[index - 1];
    const cloneRect = this.dragData.clone.element.getBoundingClientRect();

    if (type === 'onEnd') {
      position.y = this.dragData.clone.oldPosition.y + containerRect.top;
      position.x = this.dragData.clone.oldPosition.x + containerRect.left;
    }

    if (type === 'onDrop') {
      const paddings = this.getPaddings(this.elementRef.nativeElement);
      position.y = containerRect.y + paddings.top;
      position.x = containerRect.x + paddings.left;

      position.y +=
        this.options.listDirection === 'vertical'
          ? this.options.listGap * index
          : 0;
      position.x +=
        this.options.listDirection === 'horizontal'
          ? this.options.listGap * index
          : 0;

      if (targetEl) {
        position.y +=
          this.options.listDirection === 'vertical'
            ? elements
                .slice(0, index)
                .reduce(
                  (total, el) => total + el.getBoundingClientRect().height,
                  0,
                )
            : 0;

        position.x +=
          this.options.listDirection === 'horizontal'
            ? elements
                .slice(0, index)
                .reduce(
                  (total, el) => total + el.getBoundingClientRect().width,
                  0,
                )
            : 0;
      }
    }

    if (
      cloneRect.x.toFixed(1) === position.x.toFixed(1) &&
      cloneRect.y.toFixed(1) === position.y.toFixed(1)
    ) {
      return of(true);
    }

    this.renderer.setStyle(
      this.dragData.clone.element,
      'transition',
      `all ${this.animateTime}s`,
    );
    this.renderer.setStyle(
      this.dragData.clone.element,
      'top',
      `calc(${position.y}px)`,
    );
    this.renderer.setStyle(
      this.dragData.clone.element,
      'left',
      `calc(${position.x}px)`,
    );

    return fromEvent(this.dragData.clone.element, 'transitionend').pipe(
      first(),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private removeClone(): void {
    this.document
      .querySelectorAll<HTMLElement>(`.${this.cloneClass}`)
      .forEach((clone) => {
        this.renderer.removeChild(this.document.body, clone);
      });
  }

  private removePlaceholders(): void {
    this.elementRef.nativeElement
      .querySelectorAll<HTMLElement>(`.${this.placeholderClass}`)
      .forEach((placeholder) => {
        this.renderer.removeChild(this.document.body, placeholder);
      });
  }

  private clearSelection(): void {
    this.document.getSelection().removeAllRanges();

    fromEvent(this.document, 'dragstart')
      .pipe(first())
      .subscribe((event) => {
        event.preventDefault();
      });
  }

  // TODO: do it better!
  private getPaddings(el: HTMLElement): Record<string, number> {
    if (!_.isFunction(el.computedStyleMap)) {
      return {
        top: this.options.listGap ?? 0,
        left: this.options.listGap ?? 0,
        bottom: this.options.listGap ?? 0,
        right: this.options.listGap ?? 0,
      };
    }

    const styleMap = el.computedStyleMap();

    return {
      top: parseInt(styleMap.get('padding-top').toString()),
      left: parseInt(styleMap.get('padding-left').toString()),
      bottom: parseInt(styleMap.get('padding-bottom').toString()),
      right: parseInt(styleMap.get('padding-right').toString()),
    };
  }

  private initStyles(): void {
    this.draggableElements.forEach((el) => {
      this.renderer.setStyle(
        el,
        'transition',
        `transform ${this.animateTime}s`,
      );
    });
  }

  private initSubscribers(): void {
    this.animating$
      .pipe(
        tap(() => {
          this.animating = true;
        }),
        debounceTime(this.animateTime * 1000),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.animating = false;
      });

    this.dragDropService.onStart$
      .pipe(
        concatMap(() => this.dragDropService.dragEnd$.pipe(first())),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.initStyles();
      });

    this.dragDropService.onStart$
      .pipe(
        filter((data) => data.toGroupName !== this.options.group.name),
        concatMap(() =>
          this.escapeObservable$.pipe(
            first(),
            takeUntil(this.dragDropService.dragEnd$.pipe(first())),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.restoreTransform(null, null, true);
      });

    this.dragDropService.onDragOver$
      .pipe(
        pairwise(),
        tap(([prevToGroupName, newToGroupName]) => {
          if (
            this.dragData &&
            prevToGroupName !== newToGroupName &&
            this.options.group.name !== this.dragData.toGroupName
          ) {
            this.removePlaceholders();
            this.restoreTransform(null, null, true);
            this.isCloneOver = false;
          }
        }),
        filter(
          () =>
            this.options.group.name === this.dragData?.toGroupName &&
            this.options.group.put.includes(this.dragData?.fromGroupName)
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.onDragOver();
      });

    this.dragDropService.onDrop$
      .pipe(
        filter((event) => event?.toGroupName === this.options.group.name),
        delay(Constants.mousemoveThrottleTime),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.onDrop();
      });

    this.dragDropService.onEnd$
      .pipe(
        filter((event) => event?.fromGroupName === this.options.group.name),
        delay(Constants.mousemoveThrottleTime),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.onDragEnd();
      });

    let startX = 0;
    let startY = 0;

    fromEvent(this.elementRef.nativeElement, 'pointerdown')
      .pipe(
        filter((event: PointerEvent) => {
          if (
            this.dndDisabled ||
            this.document.querySelector(`.${this.cloneClass}`)
          ) {
            return false;
          }

          const draggableElement = this.getDraggable(
            event.target as HTMLElement,
          );

          if (draggableElement) {
            startX = event.clientX;
            startY = event.clientY;

            return true;
          }

          return false;
        }),
        switchMap((startEvent: PointerEvent) =>
          fromEvent(this.document, 'pointermove').pipe(
            tap((moveEvent: PointerEvent) => {
              if (
                Math.abs(moveEvent.clientX - startX) > 5 ||
                Math.abs(moveEvent.clientY - startY) > 5
              ) {
                this.onDragStart(
                  this.getDraggable(startEvent.target as HTMLElement),
                  startEvent,
                );
              }
            }),
            takeUntil(
              merge(
                fromEvent(this.document, 'pointerup'),
                this.dragDropService.onDragOver$,
              ).pipe(first()),
            ),
          ),
        ),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
