import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationPermissionAlert } from 'src/app/shared/services/browser-notification/browser-notification.interface';
import { BrowserNotificationService } from 'src/app/shared/services/browser-notification/browser-notification.service';

@Component({
  selector: 'wp-user-settings-notifications-form',
  templateUrl: './user-settings-notifications-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsNotificationsFormComponent
  implements OnInit, OnDestroy
{
  @Output() public form$ = new EventEmitter<UntypedFormGroup>();

  public browserPermissionAlert: NotificationPermissionAlert;
  public isShowAlert = false;
  public notifyCheckboxes = ['notifyOnDueDateExpired', 'workflowTaskCreated'];
  public form: UntypedFormGroup = this.fb.group({
    notification: this.fb.group(
      this.notifyCheckboxes.reduce(
        (result: Record<string, any>, value) => ({ ...result, [value]: false }),
        {},
      ),
    ),
    notifyByEmail: false,
    useBrowserNotifications: false,
  });

  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private browserNotificationService: BrowserNotificationService,
  ) {}

  public ngOnInit() {
    this.setMarkPristineFix();

    this.form$.emit(this.form);

    this.form.controls['useBrowserNotifications'].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: boolean) => {
        this.isShowAlert = value;
        this.cdr.markForCheck();
      });

    if (this.browserNotificationService.permission !== 'granted') {
      this.browserPermissionAlert =
        this.browserNotificationService.getPermissionAlert('denied');
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private setMarkPristineFix(): void {
    //TODO: check and fix this code
    const self = this;
    const origFunc = this.form.markAsPristine;
    this.form.markAsPristine = function () {
      origFunc.apply(this, arguments);
      self.cdr.markForCheck();
    };
  }
}
