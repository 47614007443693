<div
  wpFreezeTable
  class="disable-selecting"
  leftWidthStrategy="byTable"
  rightWidthStrategy="byContainer"
>
  <div name="toolbar">
    <div class="toolbar-container pt-0">
      <div class="group d-flex align-items-center">
        <wp-schedule-navigation
          [visibleParts]="scheduleNavigationVisibleParts"
        ></wp-schedule-navigation>
        <wp-filter></wp-filter>
      </div>
    </div>
  </div>

  <div name="left">
    <div name="scroll-table-header">
      <table class="table wp-nested-table" [ngStyle]="{ width: leftTableWidth + 'px' }">
        <thead>
          <tr>
            <th
              class="trim"
              style="height: 70px"
              title="{{ 'team.timeOffSchedule.user' | translate }}"
            >
              {{ 'team.timeOffSchedule.user' | translate }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table" [ngStyle]="{ width: leftTableWidth + 'px' }">
        <colgroup>
          <col style="width: 100%" />
        </colgroup>
        <tbody *ngIf="this.dataService.users.length == 0 && !(dataService.loading$ | async)">
          <tr>
            <td class="text-body-secondary no-data" [attr.colspan]="2">
              {{ 'shared.noDisplayData' | translate }}
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr *ngFor="let user of this.dataService.users; trackBy: trackId">
            <td class="user-cell" id="{{ user.id }}">
              <img class="avatar-bg" [src]="user.id | avatar: 60" />
              <div style="display: inline" id="user-{{ user.id }}">
                <button
                  title="{{ user.name }}"
                  type="button"
                  (click)="openUserInfo(user.id)"
                  class="btn btn-link trim"
                >
                  <span id="{{ user.id }}-name">{{ user.name }}</span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>

  <div name="right" [ngStyle]="{ 'margin-left': leftTableWidth - 1 + 'px' }">
    <div name="scroll-table-header">
      <table class="table wp-nested-table" [ngStyle]="{ 'min-width': getDataTableWidth() + 'px' }">
        <thead>
          <tr>
            <th
              *ngFor="let group of slotGroups; trackBy: trackId"
              [attr.colspan]="group.slotsCount"
              title="{{ group.hint }}"
            >
              {{ group.header }}
            </th>
          </tr>
          <tr>
            <th
              class="slot"
              *ngFor="let slot of slots; trackBy: trackId"
              [ngClass]="{ 'slot-active': slot.today }"
              title="{{ slot.hint }}"
            >
              {{ slot.header }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table" [ngStyle]="{ 'min-width': getDataTableWidth() + 'px' }">
        <tbody>
          <tr *ngIf="this.dataService.users.length == 0 && !(dataService.loading$ | async)">
            <td class="text-body-secondary no-data" [attr.colspan]="slots.length"></td>
          </tr>
        </tbody>
        <tbody>
          <tr *ngFor="let leaveSchedule of this.dataService.leaveSchedules; trackBy: trackId">
            <td
              *ngFor="let day of leaveSchedule.leaveScheduleDays; trackBy: trackId"
              (click)="openInfo(day, dayTarget)"
              [ngClass]="day.style"
              class="trim time-off-day"
              #dayTarget
            >
              {{ day.code }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>
</div>

<wp-loading-indicator [loading]="dataService.loading$ | async"></wp-loading-indicator>
