<div class="toolbar-container">
  <wp-expenses-view-switcher></wp-expenses-view-switcher>
  <wp-schedule-navigation
    [maxScale]="PlanningScale.Year"
    [disabled]="disabled"
  ></wp-schedule-navigation>

  <button
    *ngIf="!service.readonly"
    type="button"
    class="btn btn-default"
    [disabled]="disabled"
    (click)="projectExpensesService.openExpenseRuleModal()"
  >
    <span class="bi bi-plus-lg bi-15"></span>
    {{ 'projects.projects.recurringExpenseRules.actions.addRule' | translate }}
  </button>

  <div class="form-check">
    <label class="form-check-label">
      <input
        type="checkbox"
        class="form-check-input"
        [checked]="service.includeLaborCost"
        [disabled]="disabled"
        (click)="service.toggleLaborCost()"
      />
      {{ 'projects.projects.card.expenses.calendar.actions.includeLaborCost' | translate }}
    </label>
  </div>

  <wp-project-expenses-group-filter class="d-block ms-auto"></wp-project-expenses-group-filter>
</div>
